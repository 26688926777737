import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { flowRight as compose } from 'lodash';

import { withRouter } from 'next/router';
import withActiveLocale from '../withActiveLocale';
import routes, { Router } from '../../routes';

export const withRoutingPropType = PropTypes.shape({
  // next router props
  asPath: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,

  // aliased/wrapper props
  getRouteUrl: PropTypes.func.isRequired,
  pushRoute: PropTypes.func.isRequired,
  pushRouteAndScroll: PropTypes.func.isRequired,
  replaceRoute: PropTypes.func.isRequired,
  replaceRouteAndScroll: PropTypes.func.isRequired,
  openNewTab: PropTypes.func
});

const withRouting = BaseComponent => {
  class ComponentWithRouter extends Component {
    /**
     * A helper that will return the URL of a route with the given parameters.
     *
     * The locale parameter is automatically populated with the "activeLocale" if it's missing however
     * you can explicitly override it if you need to.
     *
     * @param {string} routeName Route name defined in routes/routes.js.
     * @param {object} params Route parameters defined in routes/routes.js.
     */
    getRouteUrl = (routeName, params = {}) => {
      const resolvedParams = this.resolveParams(params);
      const route = routes.findAndGetUrls(routeName, resolvedParams);

      // Checks if the route information that was passed in is even a valid route
      if (route.route === undefined) {
        return null;
      }

      // Route was valid so url can be returned
      return route.urls.as;
    };

    /**
     * Wrappers to enforce consistency and ease of use for programatic route control.
     *
     * Method takes a Pathname or Route.name (OrganizationSettings) and will
     * append the activeLocale as needed. Locale can be overridden by explicitly passing one in the Pathname
     * or as a parameter. The second argument is params–an object that matches query parameters as defined by
     * the route definition (routes/routes.js).
     *
     * @param {string} route Pathname (/en-ca/organization) or Route.name (OrganizationSettings)
     * @param {object} params parameters for the named routes (defined in routes/routes.js)
     * @param {object} options forwarded to Next.js ()
     */
    handleRoute = (method = 'pushRoute', callback = () => {}) => async (route, params = {}, options = {}) => {
      const resolvedMethod = this.resolveMethod(method);
      const resolvedParams = this.resolveParams(params);
      const response = await Router[resolvedMethod](route, resolvedParams, options);
      callback();
      return response;
    };

    resolveMethod = method => {
      const validMethods = ['pushRoute', 'replaceRoute', 'prefetchRoute'];
      return validMethods.includes(method) ? method : 'pushRoute';
    };

    resolveParams = params => {
      const { activeLocale } = this.props;
      // push the locale if it was not included in parameters
      return {
        ...params,
        ...(params.locale ? params.locale : { locale: activeLocale?.id ?? 'en' })
      };
    };

    scrollToTopAndFocus = () => {
      window.scrollTo(0, 0);
      document.body.focus();
    };

    openNewTab = async (route, params = {}) => {
      const url = this.getRouteUrl(route, params);
      window.open(url, '_blank');
    };

    buildProps = () => {
      const { router } = this.props;
      const blacklistRouterProps = ['components', 'push', 'prefetch', 'replace'];
      const passThroughRouterProps = omit(router, blacklistRouterProps);

      return {
        ...passThroughRouterProps,
        getRouteUrl: this.getRouteUrl,
        pushRoute: this.handleRoute('pushRoute'),
        pushRouteAndScroll: this.handleRoute('pushRoute', this.scrollToTopAndFocus),
        replaceRoute: this.handleRoute('replaceRoute'),
        replaceRouteAndScroll: this.handleRoute('replaceRoute', this.scrollToTopAndFocus),
        prefetchRoute: this.handleRoute('prefetchRoute'),
        openNewTab: this.openNewTab
      };
    };

    render() {
      // blacklist activeLocale as we don't need it doubled up anywhere
      const blacklistProps = ['router'];
      const passThroughProps = omit(this.props, blacklistProps);
      return <BaseComponent router={this.buildProps()} {...passThroughProps} />;
    }
  }

  return compose(
    withRouter,
    withActiveLocale
  )(ComponentWithRouter);
};

export default withRouting;
