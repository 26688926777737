// exclude-from-index
import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Image from '../../../../BuildingBlocks/Image';
import NavLink from '../NavLink';

const {
  publicRuntimeConfig: { CN_LOGO }
} = getConfig();
function CNLogo(props) {
  const { href, target, className, onClick } = props;

  return CN_LOGO ? (
    <NavLink onClick={onClick} href={href} target={target} className={className}>
      <Image src={CN_LOGO} alt="CN transportation logo" />
    </NavLink>
  ) : null;
}

CNLogo.propTypes = {
  /**
   * Value passed that would be acceptable for a regular anchor tag href attribute.
   */
  href: PropTypes.string,
  /**
   * Value passed that would be acceptable for a regular anchor tag target attribute.
   */
  target: PropTypes.string,
  /**
   * string of class(es) to add to the anchor.
   */
  className: PropTypes.string,
  /**
   * Function passed to handle any extra functionality than a regular page route.
   */
  onClick: PropTypes.func
};

CNLogo.defaultProps = {
  href: '',
  target: '',
  className: '',
  onClick: () => {}
};

export default CNLogo;
