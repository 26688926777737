import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import PageBackgroundWrapper from '../BuildingBlocks/PageBackgroundWrapper/PageBackgroundWrapper';
import Button from '../BuildingBlocks/Button';
import Grid from '../Utility/Grid';
import VerticalSpacing from '../Utility/VerticalSpacing';
import Text from '../BuildingBlocks/Text';

import withAnalytics, { withAnalyticsPropType } from '../../hocs/withAnalytics';
import withRouting, { withRoutingPropType } from '../../hocs/withRouting';

import PageSEO from '../PageSEO';

import messages from './messages';

class ErrorPage extends Component {
  static propTypes = {
    analytics: withAnalyticsPropType.isRequired,
    intl: intlShape.isRequired,
    router: withRoutingPropType.isRequired,
    showRetry: PropTypes.bool,
    statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    statusMessage: PropTypes.string
  };

  static defaultProps = {
    showRetry: false,
    statusCode: null,
    statusMessage: null
  };

  componentDidMount() {
    const { analytics } = this.props;

    analytics.trackRoute('Unexpected Error');
  }

  handleRetry = () => {
    const { analytics, router } = this.props;

    analytics.trackEvent('page-error', {
      errorType: 'unexpected error',
      clickID: 'retry'
    });

    if (window && document && document.referrer) {
      window.location = document.referrer;
    } else {
      // fallback but this won't refresh the page so it could still be broken
      router.back();
    }
  };

  render() {
    const { intl, showRetry, statusCode, statusMessage } = this.props;

    const message =
      statusMessage ||
      intl.formatMessage(messages.defaultErrorMessage, {
        type: statusCode || 'UNKNOWN'
      });

    return (
      <PageBackgroundWrapper>
        <PageSEO title={intl.formatMessage(messages.seoTitle)} />
        <Grid.Container>
          <Grid.Row>
            <Grid.Col md={12} lg={10} offset={{ lg: 1 }} align="center">
              <VerticalSpacing>
                <Text.HeadingHero tagName="h2" color="quaternary" align="center" noMargins>
                  {statusCode}
                </Text.HeadingHero>
                <Text.CopyBody tagName="p" align="center">
                  {message}
                </Text.CopyBody>
                {showRetry && (
                  <Grid.ScreenClassRender
                    render={screenClass => (
                      <Button onClick={this.handleRetry} fullWidth={['xs', 'sm', 'md'].includes(screenClass)}>
                        {intl.formatMessage(messages.retryButton)}
                      </Button>
                    )}
                  />
                )}
              </VerticalSpacing>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </PageBackgroundWrapper>
    );
  }
}

export default compose(
  injectIntl,
  withAnalytics(),
  withRouting
)(ErrorPage);
