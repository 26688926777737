import get from 'lodash.get';

import LocalStorage from '../../lib/storage/local';
import MemoryStorage from '../../lib/storage/memory';

import { MUTATION_LOGIN, MUTATION_CHANGE_ACTIVE_ORGANIZATION } from './gql';

export const createAuthStore = (reset = false) => {
  let store;
  const AUTH_STORE_KEY = 'LLV2_GQL_AUTH';
  const initial = { token: null, expiry: null, authError: null, showUidWarning: true };
  if (!process.browser) {
    store = new MemoryStorage(initial);
  } else {
    store = new LocalStorage(AUTH_STORE_KEY, initial);
  }

  // need to check if any tokens we had are expired.
  const expiry = store.get('expiry', null);
  if (expiry || reset) {
    const rightNow = Math.floor(Date.now() / 1000);
    const isExpired = rightNow >= expiry;
    if (isExpired) {
      /* eslint-disable-next-line */
      console.warn('TOKEN IS EXPIRED, PURGING VALUES', rightNow, expiry);
      store.set('token', null);
      store.set('expiry', null);
      store.set('authError', null);
      store.set('showUidWarning', true);
    }
  }

  return store;
};

let AUTH_STORE = null;
export const getAuthStore = (recreate = false) => {
  if (!AUTH_STORE || recreate) {
    AUTH_STORE = createAuthStore();
  }
  return AUTH_STORE;
};

export const defaultAuthState = {
  authError: null
};

export const setAuthTokens = async (client, store, variables) => {
  const { token = null, expiry = null, authError = null } = variables;
  store.set('token', token);
  store.set('expiry', expiry);
  store.set('authError', authError);
  store.set('showUidWarning', true);
};

export const GQLLogin = async (client, store, variables) => {
  let errorMessage = null;
  let accountToken = '';
  let tokenExpiry = '';

  const { data, errors } = await client.mutate({
    errorPolicy: 'all',
    mutation: MUTATION_LOGIN,
    variables
  });
  if (errors && errors.length > 0) {
    errorMessage = get(errors, '[0].data.errorMessage', 'Unknown authentication error.');
  }
  const message = get(errors, '0.message', 'An unknown authentication error occurred.');
  if (message.includes('Wrong email')) {
    errorMessage = 'Wrong email or password';
  }
  if (message === 'This operation requires the user to be active') {
    errorMessage =
      'Your account has been deactivated. Please contact your Organization Admin or Lodgelink Support for assistance.';
  }

  accountToken = get(data, 'login.accountToken', '');
  tokenExpiry = get(data, 'login.tokenExpiry', '');
  const tokenVars = { token: accountToken, expiry: tokenExpiry, authError: errorMessage, showUidWarning: true };
  await setAuthTokens(client, store, tokenVars);
  await client.resetStore();
  return errorMessage;
};

export const GQLLogout = async (client, store) => {
  // add any extra clean up tasks here as they become required over time.
  const tokenVars = { token: null, expiry: null, authError: null, showUidWarning: false };
  await setAuthTokens(client, store, tokenVars);
  await client.clearStore();
  try {
    if (process.browser) {
      window.localStorage.clear();
      window.location.reload();
    }
  } catch (error) {
    /* eslint-disable-next-line */
    console.log('Error clearing logout session information.', error);
  }
};

export const GQLChangeOrganization = async (client, store, variables) => {
  try {
    await client.mutate({
      errorPolicy: 'all',
      mutation: MUTATION_CHANGE_ACTIVE_ORGANIZATION,
      variables
    });
    await client.resetStore();
  } catch (error) {
    /* eslint-disable */
    console.log('MUTATION_CHANGE_ACTIVE_ORGANIZATION');
    console.log({ error });
    console.log({ variables });
    /* eslint-enable */
    await GQLLogout(client, store);
  }
};

export const createVersionInfoStore = () => {
  let store;
  const VERSION_INFO_STORE_KEY = 'LLV2_VERSION_INFO';

  if (!process.browser) {
    store = new MemoryStorage({});
  } else {
    store = new LocalStorage(VERSION_INFO_STORE_KEY, {});
  }

  return store;
};

let VERSION_INFO_STORE = null;
export const getVersionInfoStore = (recreate = false) => {
  if (!VERSION_INFO_STORE || recreate) {
    VERSION_INFO_STORE = createVersionInfoStore();
  }
  return VERSION_INFO_STORE;
};
