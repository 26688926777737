const OrganizationPermission = {
  VIEW_BOOKING: 'VIEW_BOOKING',
  GET_CUSTOM_CATEGORIES: 'GET_CUSTOM_CATEGORIES',
  EDIT_CUSTOM_CATEGORIES: 'EDIT_CUSTOM_CATEGORIES',
  EDIT_BOOKING: 'EDIT_BOOKING',
  CREATE_BOOKING: 'CREATE_BOOKING',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  EDIT_ORGANIZATION_CONTACTS: 'EDIT_ORGANIZATION_CONTACTS',
  INVITE_ORGANIZATION_MEMBERS: 'INVITE_ORGANIZATION_MEMBERS',
  CREATE_CREDIT_APPLICATION: 'CREATE_CREDIT_APPLICATION',
  EDIT_CREDIT_APPLICATION: 'EDIT_CREDIT_APPLICATION',
  FINALIZE_CREDIT_APPLICATION: 'FINALIZE_CREDIT_APPLICATION',
  GET_CREW: 'GET_CREW',
  EDIT_CREW: 'EDIT_CREW',
  VIEW_PAYMENT_CODES: 'VIEW_PAYMENT_CODES',
  MANAGE_PAYMENT_CODES: 'MANAGE_PAYMENT_CODES',
  SHARE_PAYMENT_CODES: 'SHARE_PAYMENT_CODES',
  ADM_FLAG_MGMT_VIEW: 'ADM_FLAG_MGMT_VIEW',
  VIEW_COST_TRACKING_CODES: 'VIEW_COST_TRACKING_CODES',
  MANAGE_COST_TRACKING_CODES: 'MANAGE_COST_TRACKING_CODES',
  SHARE_COST_TRACKING_CODES: 'SHARE_COST_TRACKING_CODES',
  VIEW_REPORTS: 'VIEW_REPORTS',
  ADM_FLAG_PAG_BOOKINGS: 'ADM_FLAG_PAG_BOOKINGS',
  ADM_FLAG_CREW_SWAP: 'ADM_FLAG_CREW_SWAP',
  ADM_FLAG_CREW_MGMT: 'ADM_FLAG_CREW_MGMT',
  CUS_CREATE_BLOCK_BOOKING: 'CUS_CREATE_BLOCK_BOOKING',
  productTeam: 'PRODUCT_TEAM',
  VIEW_SUBCONTRACTOR_BOOKINGS: 'VIEW_SUBCONTRACTOR_BOOKINGS',
  VIEW_ACTIVE_BOOKINGS: 'VIEW_ACTIVE_BOOKINGS',
  VIEW_PAST_BOOKINGS: 'VIEW_PAST_BOOKINGS',
  VIEW_BOOKINGS_ATTENDING: 'VIEW_BOOKINGS_ATTENDING',
  VIEW_GUEST_BOOKINGS: 'VIEW_GUEST_BOOKINGS',
  EDIT_DUTY_OF_CARE: 'EDIT_DUTY_OF_CARE',
  EDIT_USER_PROFILE: 'EDIT_USER_PROFILE',
  CREATE_MULTI_GUEST_BOOKING: 'CREATE_MULTI_GUEST_BOOKING',
  ADM_FLAG_CREW_REG_MGMT: 'ADM_FLAG_CREW_REG_MGMT'
};

export default OrganizationPermission;
